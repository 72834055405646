import React, { useEffect, useRef, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import qs from "qs"
import _isEmpty from "lodash/isEmpty"
import { useTranslation } from "next-i18next"
import useWindowResize from "@/hooks/useWindowResize"
import { apim } from "@/constants/api"
import { getConfig } from "@/constants/config"
import { getUserPersona, getPDPUrl, getShortenedUrl } from "@/utils/helper"
import {
  getProductInfo,
  getDescriptionWithElipsis,
  flags,
} from "@/components/productCard/Hotspot/utils"
import { getPresetUrl } from "@/utils"
import {
  addAnalyticsEventForFavouriteAddRemove,
  shopRoomhandleProductClick,
} from "@/components/ShopTheRoomCard/analytics"
import { showNewBadge } from "@/utils/helper"
import usePdpi18n from "@/i18n/usePdpi18n"
import {
  addProduct,
  removeItems,
  selectFavoritesState,
} from "@/store/features/favoritesSlice"
import { selectCartState } from "@/store/features/cartSlice"
import {
  selectAuthState,
  setAuthModalVisibility,
} from "@/store/features/authSlice"
import { selectGenericState, showToast } from "@/store/features/genericSlice"

import MissingImage from "@/public/images/image-missing.png"
import wishIcon from "@/public/icons/wish.svg"
import filledWishIcon from "@/public/icons/wishlist-gray-filled.svg"
import styles from "@/components/productCard/Hotspot/index.module.scss"

const ProductCard = props => {
  const {
    authorData: { skewId = "", presetConfigs = "{}" },
    isDefaultProductCard = true,
    isFavoriteIcon = true,
    selectedProduct = "",
    hotspotClicked = false,
  } = props

  const { t } = useTranslation("common")
  const [width] = useWindowResize()
  const dispatch = useDispatch()
  const { toaster } = useSelector(selectGenericState)
  const { cart } = useSelector(selectCartState)
  const { favorites } = useSelector(selectFavoritesState)
  const { isAuth } = useSelector(selectAuthState)
  const presetConfig = JSON.parse(presetConfigs)
  const myRef = useRef(null)
  const [productData, setProductData] = useState({
    image: "",
    isFavorite: false,
  })
  const [config, setConfig] = useState({})
  const [swatchUrl, setSwatchUrl] = useState("")
  const [loginFavouriteCallback, setLoginFavouriteCallback] = useState(null)
  const [toasterContent, setToasterContent] = useState({
    successMessage: "",
    unfavoriteSuccessMessage: "",
  })
  const [pdpUrl, setPdpUrl] = useState("")
  const [badgesProps, setBadgesProps] = useState({})
  const { general = {} } = config
  const {
    newBadgeName = "",
    saleBadgeName = "",
    exclusiveBadgeName = "",
    discontinuedBadgeName = "",
  } = general
  const badgeTextObj = {
    new: newBadgeName,
    onSale: saleBadgeName,
    exclusive: exclusiveBadgeName,
    discontinued: discontinuedBadgeName,
  }
  const staticTexts = usePdpi18n("", badgeTextObj, "")

  useEffect(() => {
    if (selectedProduct === skewId) myRef?.current?.scrollIntoView()
  }, [selectedProduct, skewId, myRef, hotspotClicked])

  useEffect(() => {
    getConfig().then(config => {
      setConfig(config)
      setSwatchUrl(config?.general?.swatchUrl ?? "")
    })
  }, [])

  useEffect(() => {
    if (skewId && !_isEmpty(config)) {
      const persona = getUserPersona()
      const { siteName: brandName = "" } = { ...config?.general }
      const fl = flags(persona)
      setProductData({ ...productData })
      try {
        const language = config?.internationalization?.language ?? "en"
        apim
          .get(`/search/plp`, {
            params: {
              fl: fl.join(","),
              q: "*:*",
              fq: [`sku_s:("${skewId}")`, `language_s:("${language}")`],
              collections: brandName.toLowerCase(),
              profilename: `profile_${brandName.toLowerCase()}_General`,
            },
            paramsSerializer: params => {
              return qs.stringify(params, {
                arrayFormat: "repeat",
                encode: false,
              })
            },
          })
          .then(res => {
            if (res && res.data) {
              const productData = res.data?.response?.docs[0] ?? {}
              const {
                ctId_s: productId = "",
                RegionReleaseforShipment_dt: releaseforShipmentDate = "",
                ProductIsExclusive_s: productExclusive = "",
              } = productData
              let imageId = ""
              if (skewId.includes("-")) {
                const skewIdPart = skewId.split("-")
                const selectedVariant = skewIdPart.at(-1)
                const imageData = productData["Color.TPR.Details_ss"]
                  .find(str => str.includes(`-${selectedVariant}`))
                  .split("/")
                imageId = imageData[imageData.length - 1]
              }
              const favoriteItem = favorites?.lineItems?.find(
                favorite => favorite.productId === productId
              )
              const isFavorite = favoriteItem ? true : false
              const { currencySign = "" } = { ...config?.internationalization }
              const badgesProps = {
                isNewProduct: showNewBadge(releaseforShipmentDate),
                badgeVal: productData[`priceList.${persona}.saleOffer_s`] ?? "",
                exclusiveBadge: productExclusive,
              }
              setBadgesProps(badgesProps)
              setProductData({
                ...productData,
                imageId,
                currencySign,
                isFavorite,
              })
            } else {
              // eslint-disable-next-line no-console
              console.error("Error occured while fetching product data")
            }
          })
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error("Error occured while fetching product data", err)
      }
    }
  }, [config, favorites, isAuth])

  useEffect(() => {
    if (loginFavouriteCallback && isAuth) {
      handleFavorite(loginFavouriteCallback)
    }
  }, [loginFavouriteCallback, isAuth])

  useEffect(() => {
    setToasterMessage()
  }, [toaster])

  const setToasterMessage = async () => {
    if (!_isEmpty(toaster?.content)) {
      const { successMessage, unfavoriteSuccessMessage } = toaster.content
      setToasterContent({
        successMessage: await getShortenedUrl(successMessage),
        unfavoriteSuccessMessage: await getShortenedUrl(
          unfavoriteSuccessMessage
        ),
      })
    }
  }

  const handleLogin = e => {
    e.preventDefault()
    e.stopPropagation()
    window.loginFavouriteCallback = () => {
      setLoginFavouriteCallback(e)
    }
    dispatch(setAuthModalVisibility({ show: true }))
  }

  const handleFavorite = e => {
    const { ctId_s: productId = "", isFavorite = false } = productData
    window.preActiveElement = e.target
    e.stopPropagation()
    e.preventDefault()
    if (isFavorite) {
      const favProduct = favorites?.lineItems?.find(
        fav => fav.productId === productId
      )
      if (favProduct) {
        if (!isAuth) {
          handleLogin(e)
        } else {
          dispatch(removeItems([productId]))
            .unwrap()
            .then(() => {
              const productInfo = getProductInfo({
                productData,
                skewId,
                needsJSON: false,
                cart,
                config,
                description,
                persona,
              })
              addAnalyticsEventForFavouriteAddRemove(false, productInfo)
              dispatch(
                showToast({
                  message:
                    toasterContent.unfavoriteSuccessMessage ||
                    t("kf.favorites.removeItem"),
                  isVisible: true,
                })
              )
            })
            .catch(err => {
              dispatch(
                showToast({
                  message: t("kf.favorites.removeItemError"),
                  isVisible: true,
                })
              )
            })
        }
      }
    } else {
      if (!isAuth) {
        handleLogin(e)
      } else {
        dispatch(addProduct(skewId))
          .unwrap()
          .then(() => {
            const productInfo = getProductInfo({
              productData,
              skewId,
              needsJSON: false,
              cart,
              config,
              description,
              persona,
            })
            addAnalyticsEventForFavouriteAddRemove(true, productInfo)
            dispatch(
              showToast({
                message:
                  toasterContent.successMessage || t("kf.favorites.success"),
                isVisible: true,
              })
            )
          })
          .catch(err => {
            dispatch(
              showToast({
                message: t("kf.favorites.error"),
                isVisible: true,
              })
            )
          })
      }
    }
  }

  const {
    slug_s: slug = "",
    currencySign = "",
    imageId = "",
    Product_Category: productCategory = "",
    productName_s: productName = "",
    isFavorite = false,
  } = productData
  let { ProductDescriptionProductShort_s: description = "" } = productData
  const name = productName ? productName.split(" ").slice(" ")[0] : ""
  const persona = getUserPersona()
  const price = Number(
    productData[`priceList.${persona}.price_d`] ?? 0
  ).toLocaleString(undefined, {
    maximumFractionDigits: 2,
    minimumFractionDigits: 2,
  })
  const isMobile =
    typeof window !== "undefined"
      ? window.matchMedia("(max-width: 1038px)").matches
      : null
  description = getDescriptionWithElipsis(isMobile, description)
  useEffect(() => {
    if (!_isEmpty(productData) && slug) {
      getPDPUrl(productCategory, slug).then(url => setPdpUrl(url))
    }
  }, [productData, slug])

  const getBadgeText = () => {
    const {
      isNewProduct = "",
      exclusiveBadge = "",
      badgeVal = "",
    } = badgesProps
    const {
      new: newItem = "",
      newExclusive = "",
      exclusive = "",
      sale = "",
    } = staticTexts
    const badgeTxt = isNewProduct
      ? exclusiveBadge
        ? newExclusive
        : newItem
      : exclusiveBadge
      ? exclusive
      : badgeVal
      ? sale
      : ""
    return badgeTxt
  }

  return (
    <div ref={myRef} className={styles.hotspotProductCardWraper}>
      <div className="hotspotProductCard">
        <a
          onClick={() =>
            shopRoomhandleProductClick({
              productData,
              sku: skewId,
              url: `${pdpUrl}${skewId ? "?skuId=" + skewId : ""}}`,
              cart,
              config,
              description,
              persona,
            })
          }
          href={`${pdpUrl}${skewId ? "?skuId=" + skewId : ""}`}
        >
          <div className="hotspotProductContainer">
            <div className="imageContainer">
              <img
                className="imageCard"
                alt=""
                src={
                  getPresetUrl(width, 0, swatchUrl, imageId, presetConfig) ??
                  MissingImage
                }
                onError={e => (e.target.src = MissingImage)}
              />
              {isFavoriteIcon ? (
                <div className="product-tile__share">
                  <button
                    id={`kf-add-to-fav-${skewId}`}
                    className="product-tile__share-icon"
                    tabIndex="0"
                    onClick={handleFavorite}
                  >
                    <img
                      role="presentation"
                      src={isFavorite ? filledWishIcon.src : wishIcon.src}
                      alt=""
                    />
                  </button>
                </div>
              ) : null}
            </div>
            {isDefaultProductCard ? (
              <div className="detailsContainer">
                {getBadgeText() ? (
                  <div className="badgeTextContaoner">
                    <p className="textStyle">{getBadgeText()}</p>
                  </div>
                ) : null}
                <div className="titleContainer">
                  <div className="name">{name}</div>
                  <div className="price">{`${currencySign}${price}`}</div>
                </div>
                <div className="descriptionContainer">{description}</div>
              </div>
            ) : null}
          </div>
        </a>
      </div>
    </div>
  )
}

export default ProductCard
