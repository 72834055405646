import React, { useEffect, useRef, useState } from "react"
import { useTranslation } from "next-i18next"
import debounce from "lodash/debounce"

import { MIN_DELAY } from "@/constants"
import { getShortenedUrl } from "@/utils/helper"
import {
  addAnalyticsEventForPrevNext,
  addAnalyticsEventForImageTitle,
} from "@/components/ShopTheRoomCard/analytics"

import DynamicImage from "@/components/core/Image/DynamicImage"
import ProductCard from "@/components/productCard/v3/ProductCard"

const ShopTheRoomCardModalView = ({
  skuIds = [],
  presetConfigs = "{}",
  imageLink = "",
  cardTitle = "",
  ctalink = "",
  isModal = true,
  altModel = "",
  selectedProduct = "",
  hotspotClicked = false,
  setHotspotClicked = () => {},
  invokedFrom = "button",
  setInvokedFrom = () => {},
}) => {
  const { t } = useTranslation("common")
  const selectedIndex = skuIds.indexOf(selectedProduct)
  const [visibleSkuIdsObj, setVisibleSKuIds] = useState({
    currentPageSkus: [skuIds[0]],
    visibleQuantity: 1,
    firstIndex: 0,
    isLeftDisabled: true,
    isRightDisabled: false,
  })

  const [shopTheRoomLink, setShopTheRoomLink] = useState("")

  const productCardContainerRef = useRef(null)
  const productCardRef = useRef(null)
  let localVisibleQuantity = visibleSkuIdsObj.visibleQuantity
  let localInnerWidth = window.innerWidth

  const staticTexts = {
    title: t("kf.shoptheroom.modal.text"),
  }

  useEffect(() => {
    updateVisibleSkuIdsObject(true)
    window.addEventListener("resize", handleOnResize)
    return () => {
      window.removeEventListener("resize", handleOnResize)
    }
  }, [])

  useEffect(() => {
    if (ctalink) {
      getShortenedUrl(ctalink).then(link => setShopTheRoomLink(link))
    }
  }, [ctalink])

  const handleOnResize = debounce(() => {
    if (
      productCardContainerRef.current?.clientWidth >
      productCardRef.current.clientWidth * (localVisibleQuantity + 1)
    ) {
      updateVisibleSkuIdsObject(true)
    } else if (localInnerWidth > window.innerWidth) {
      const difference = localInnerWidth - window.innerWidth
      difference >= productCardRef.current.clientWidth &&
        updateVisibleSkuIdsObject(false, difference)
    }
    localInnerWidth = window.innerWidth
  }, MIN_DELAY)

  const updateVisibleSkuIdsObject = (isAddItem, difference = 0) => {
    if (skuIds.length >= visibleSkuIdsObj.currentPageSkus.length) {
      const visibleSkuIdsTemp = [...visibleSkuIdsObj.currentPageSkus]
      let visibleQuantity1 = visibleSkuIdsTemp.length
      const { isLeftDisabled, isRightDisabled, firstIndex } = visibleSkuIdsObj

      const nextSkuIds = skuIds.slice(firstIndex + 1)
      nextSkuIds.forEach((skuId, index) => {
        if (
          productCardContainerRef.current?.clientWidth >
          productCardRef.current?.clientWidth * (index + 2)
        ) {
          visibleQuantity1++
        }
        if (
          visibleSkuIdsTemp.findIndex(tempSkuId => tempSkuId === skuId) !==
          index
        ) {
          visibleSkuIdsTemp.push(skuId)
        }
      })
      if (!isAddItem) {
        let index = 1
        do {
          visibleQuantity1 = localVisibleQuantity - index
        } while (difference > productCardRef.current?.clientWidth * index++)
      }
      localVisibleQuantity = visibleQuantity1 <= 0 ? 1 : visibleQuantity1
      setVisibleSKuIds({
        currentPageSkus: visibleSkuIdsTemp,
        firstIndex,
        visibleQuantity: localVisibleQuantity,
        isLeftDisabled,
        isRightDisabled,
      })
    }
  }

  const handleNextPrev = (
    { target: { className = "" } = {} },
    navigationType
  ) => {
    addAnalyticsEventForPrevNext(navigationType)
    let firstIndex = visibleSkuIdsObj.firstIndex
    const visibleQuantity = visibleSkuIdsObj.visibleQuantity
    if (
      className === "product-card-scroll" &&
      !visibleSkuIdsObj.isLeftDisabled
    ) {
      firstIndex = firstIndex - visibleQuantity
      firstIndex = firstIndex < 0 ? 0 : firstIndex
      setVisibleSKuIds(prevObje => ({
        firstIndex,
        currentPageSkus: prevObje.currentPageSkus,
        visibleQuantity,
        isLeftDisabled: firstIndex === 0,
        isRightDisabled: false,
      }))
    } else if (
      className === "product-card-scroll__right" &&
      !visibleSkuIdsObj.isRightDisabled
    ) {
      firstIndex = firstIndex + visibleQuantity
      let lastIndex = firstIndex + visibleQuantity
      lastIndex = lastIndex > skuIds.length ? skuIds.length : lastIndex
      setVisibleSKuIds(prevObje => ({
        firstIndex,
        currentPageSkus: prevObje.currentPageSkus,
        visibleQuantity,
        isLeftDisabled: false,
        isRightDisabled: lastIndex === skuIds.length,
      }))
    }
  }
  useEffect(() => {
    const noOfLoops =
      Math.abs(
        selectedIndex -
          visibleSkuIdsObj.firstIndex +
          visibleSkuIdsObj.visibleQuantity -
          1
      ) + 1
    const val = selectedIndex - visibleSkuIdsObj.firstIndex
    if (val < 0 || (val > 3 && invokedFrom !== "button")) {
      setInvokedFrom("")
    }
    if (selectedIndex > 0 && invokedFrom !== "button") {
      if (val > 3) {
        for (let i = 0; i < noOfLoops; i++) {
          handleNextPrev(
            { target: { className: "product-card-scroll__right" } },
            "right"
          )
        }
        setHotspotClicked(!hotspotClicked)
      } else if (val < 0) {
        for (let i = 0; i < noOfLoops; i++) {
          handleNextPrev(
            { target: { className: "product-card-scroll" } },
            "left"
          )
        }
        setHotspotClicked(true)
      } else {
        setHotspotClicked(true)
      }
    }
  }, [
    selectedIndex,
    visibleSkuIdsObj.firstIndex,
    visibleSkuIdsObj.visibleQuantity,
    invokedFrom,
  ])

  return (
    <>
      {isModal ? (
        <div className="card-modal-image-container">
          <DynamicImage
            width="100%"
            style={{ aspectRatio: "1.333/1" }}
            imgUrl={imageLink}
            alt={altModel}
          />
          <p className="image-title">
            <a
              href={shopTheRoomLink}
              role="link"
              onClick={() => addAnalyticsEventForImageTitle(cardTitle)}
            >
              {cardTitle}
            </a>
          </p>
        </div>
      ) : null}
      <div
        className="card-modal-product-card-container"
        ref={productCardContainerRef}
      >
        {isModal ? (
          <p className="product-cards-title">{staticTexts.title}</p>
        ) : null}
        <div className="product-cards-container">
          {visibleSkuIdsObj?.currentPageSkus?.length > 0
            ? visibleSkuIdsObj.currentPageSkus.map((skuId, index) => {
                const isVisible =
                  visibleSkuIdsObj.firstIndex <= index &&
                  index <
                    visibleSkuIdsObj.firstIndex +
                      visibleSkuIdsObj.visibleQuantity
                const extraProp =
                  isVisible && visibleSkuIdsObj.firstIndex === index
                    ? {
                        ref: productCardRef,
                      }
                    : {}
                return (
                  <div
                    className={`product-card${
                      isVisible ? "" : "__not-display"
                    }`}
                    key={`${skuId}-${index}`}
                    {...extraProp}
                  >
                    <ProductCard
                      selectedProduct={selectedProduct}
                      authorData={{ skewId: skuId, presetConfigs }}
                      isDefaultProductCard={false}
                      hotspotClicked={hotspotClicked}
                    />
                  </div>
                )
              })
            : null}
        </div>
        {skuIds.length > visibleSkuIdsObj?.visibleQuantity && (
          <div className="button-container">
            <div className="scroll-wrapper">
              <button
                className={`product-card-scroll${
                  visibleSkuIdsObj.isLeftDisabled ? "__button-disable" : ""
                }`}
                onClick={e => {
                  setInvokedFrom("button")
                  handleNextPrev(e, "left")
                }}
              />
              <button
                className={`product-card-scroll__right${
                  visibleSkuIdsObj.isRightDisabled ? "__button-disable" : ""
                }`}
                onClick={e => {
                  setInvokedFrom("button")
                  handleNextPrev(e, "right")
                }}
              />
            </div>
          </div>
        )}
      </div>
    </>
  )
}

export default ShopTheRoomCardModalView
