import React, { useEffect, useRef } from "react"
import styles from "@/components/ShopTheRoomDetail/v1/modal.module.scss"
import ProductCard from "@/components/productCard/v3/ProductCard"
import Image from "next/image"
import CloseIcon from "@/public/icons/close.svg"
import htmlParser from "html-react-parser"
const ShopTheRoomToaster = ({
  isOpen,
  onClose,
  skuIds,
  presetConfigs,
  modalTitle,
  modalDesc,
  modalCtaTitle,
  setIsModalOpen,
  modalCtaLink,
  linkNewTabToaster,
}) => {
  const modalElement = useRef()
  useEffect(e => {
    const clickHandler = e => {
      if (!modalElement.current) {
        return
      }
      if (!modalElement.current.contains(e.target)) {
        setIsModalOpen(false)
      }
    }
    document.addEventListener("click", clickHandler, true)
    return () => document.removeEventListener("click", clickHandler)
  }, [])
  return isOpen ? (
    <>
      <div ref={modalElement} className={styles.modalWrapper}>
        <Image
          className="close-button"
          alt="close"
          src={CloseIcon}
          onClick={() => onClose("modal")}
        />

        <div className="modal-content">
          <div className="modal-content-div" style={{ overflow: "auto" }}>
            {modalTitle ? (
              <div className="modal-title">{htmlParser(modalTitle)}</div>
            ) : null}

            {modalCtaTitle ? (
              <div className="cta-wrapper">
                <a
                  rel="noreferrer"
                  target={linkNewTabToaster ? "_blank" : "_self"}
                  href={modalCtaLink}
                  className="modal-cta"
                >
                  {modalCtaTitle}
                </a>
              </div>
            ) : null}
            {modalDesc ? (
              <div className="modal-description">{htmlParser(modalDesc)}</div>
            ) : null}

            <div className="modal-products">
              {skuIds?.map((sku, id) => {
                return (
                  <ProductCard
                    isDefaultProductCard={false}
                    toasterVar
                    key={sku}
                    authorData={{
                      skewId: sku,
                      presetConfigs: presetConfigs,
                    }}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  ) : null
}

export default ShopTheRoomToaster
