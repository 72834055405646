import {
  playVideoDatalayer,
  shareVideoDatalayer,
} from "@/components/Default/Video/videoAnalytics"
import { VIDEO_EVENTS } from "@/constants"
import { addMediaToFavorites, removeFavorites } from "./helper"

export const onVolumeChange = ref => {
  if (ref) {
    const { muted, volume } = ref
    if (volume == 0 && muted) {
      ref.setAttribute("volume", 1)
      ref.setAttribute("muted", false)
    }
  }
}

// will not work as the element is stacked behind. existing code also have same behavior
export const onClickHeroVideo = (event, ref) => {
  event.preventDefault()
  ref?.paused ? ref?.play() : ref?.pause()
}

export const onVideoInteraction = (
  action,
  cbVideoPlayed,
  cbVideoPlaying,
  ref,
  video,
  autoPlay,
  setFavVideoIdCb
) => {
  cbVideoPlayed()
  if (action === VIDEO_EVENTS.PLAY) {
    cbVideoPlaying(true)
    if (!autoPlay) {
      playVideoDatalayer(ref, video, setFavVideoIdCb)
    }
  }
  if (action === VIDEO_EVENTS.PAUSE) {
    cbVideoPlaying(false)
  }
  if (action === VIDEO_EVENTS.ENDED) {
    if (!autoPlay) {
      playVideoDatalayer(ref, video, setFavVideoIdCb)
    }
    ref.play()
  }
}

export const onPlayVideoInModal = (
  event,
  pageType,
  videoContainerRef,
  videoRef
) => {
  if (pageType == "modalWindow") {
    event.preventDefault()
    if (
      videoContainerRef &&
      !videoContainerRef?.classList?.contains("open-video-module")
    ) {
      videoContainerRef.classList.add("open-video-module")
      document.body.classList.add("modal-open")
      videoRef?.play()
    }
  }
}

export const onPlayVideo = (
  videoRef = {},
  callbackFunction = () => {},
  videoDesktopPoster = "",
  videoMobilePoster = "",
  video = "",
  isVideoPlaying = false
) => {
  if (videoRef?.paused) {
    videoRef?.play()
  } else {
    videoRef?.pause()
  }
  if (video) {
    const callbackResponse = videoRef?.paused ? true : false
    callbackFunction && callbackFunction(callbackResponse)
  } else if (videoDesktopPoster || videoMobilePoster) {
    const callbackResponse = !isVideoPlaying
    callbackFunction && callbackFunction(callbackResponse)
  }
}

export const onVideoPopupClose = (videoContainerRef, videoRef, video) => {
  videoContainerRef.classList.remove("open-video-module")
  document.body.classList.remove("modal-open")
  videoRef?.pause()
  playVideoDatalayer(videoRef, video, null, true)
}

export const onShareVideo = (
  e,
  shareVideoCallback,
  eventPageType,
  componentZone,
  componentLinkType,
  videoTitle
) => {
  shareVideoCallback && shareVideoCallback()
  if (window.shareSrcElement === undefined || window.shareSrcElement === null) {
    window.shareSrcElement = e.target.children[0]
  }
  shareVideoDatalayer(
    eventPageType,
    componentZone,
    componentLinkType,
    videoTitle
  )
}

export const onFavVideo = (
  event,
  asset = "",
  url = "",
  title = "",
  caption = "",
  videoname = "",
  favVideoId = "",
  favRef = {},
  toasterContent = {}
) => {
  const { successMessage = "", unfavoriteSuccessMessage = "" } = toasterContent
  let _$$closest
  const id = event.target.getAttribute("data-id")
  const sharedZoneName =
    (_$$closest = $(void 0).closest(".cmp-container")) === null ||
    _$$closest === void 0
      ? void 0
      : _$$closest.attr("id")

  if (id) {
    removeFavorites([id], favRef, unfavoriteSuccessMessage)
  } else {
    const assetUrl = asset

    let _assetUrl
    const url =
      (_assetUrl = assetUrl) !== null &&
      _assetUrl !== void 0 &&
      _assetUrl.startsWith("/")
        ? window.location.origin + assetUrl
        : assetUrl
    addMediaToFavorites(
      "video",
      title,
      url,
      successMessage,
      "",
      caption,
      sharedZoneName,
      videoname,
      favVideoId
    )
  }
}

export const onPlayInspireVideo = (
  inpageVideo = {},
  inspireVideo = {},
  closeBtnRef = {}
) => {
  inpageVideo?.pause()
  document.body.classList.add("modal-open")
  inspireVideo.style.display = "block"
  inspireVideo?.play()
  inspireVideo.muted = false
  closeBtnRef.style.display = "block"
}

export const onPlayInspireVideoClose = (
  inpageVideo = {},
  inspireVideo = {},
  closeBtnRef = {}
) => {
  inspireVideo?.pause()
  document.body.classList.remove("modal-open")
  inspireVideo.muted = true
  inspireVideo.style.display = "none"
  inpageVideo?.play()
  closeBtnRef.style.display = "none"
}
